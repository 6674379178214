<template>
  <div>
    <h1>当前版本v1.2.4</h1>
    <h2>
      历史更新记录
    </h2>
    <div>
      <div>
        <h3>
          v1.2.4
        </h3>
        <p>2022年8月11日</p>
        <p>CI-CD部署测试</p>
      </div>
      <div>
        <h3>
          v1.2.3
        </h3>
        <p>2022年8月2日</p>
        <p>1. 取消说明书下载</p>
        <p>1. 国际区号选择bug修复</p>
      </div>
      <div>
        <h3>
          v1.2.2
        </h3>
        <p>2022年8月1日</p>
        <p>落查优化输入提示</p>
      </div>
      <div>
        <h3>
          v1.2.1
        </h3>
        <p>2022年7月29日</p>
        <p>添加产品使用说明书</p>
      </div>
      <div>
        <h3>
          v1.2.0
        </h3>
        <p>2022年7月27日</p>
        <p>用户方案提交方式优化</p>
      </div>
      <div>
        <h3>
          v1.1.0
        </h3>
        <p>2022年7月21日</p>
        <p>大量样式变更</p>
      </div>
      <div>
        <h3>
          v1.0.0
        </h3>
        <p>2022年1月28日</p>
        <p>初始版本上线</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
